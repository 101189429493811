<template>
  <div>
    <el-container>
      <el-header class="Title">
        学习目标管理
      </el-header>
      <el-main class="GoalAndRecord">
        <div class="Header">
          <div class="ListHeader">学习目标列表</div>
          <div class="RecordHeader">打卡记录</div>
        </div>
        <div class="Body">
          <div class="GoalListContent">
            <div class="GoalListContentDetails">
              <el-collapse accordion v-model="activeNames_list">
                <el-collapse-item v-for="(item,index) in usergoallistmiddle" :name="index" :key="index">
                  <template slot="title" class="GoalName">
                    {{item.goalName}}
                  </template>
                  <div class="ItemInfo">
                    <div class="itemTitle">标题：{{item.goalName}}</div>
                    <div class="itemDate">开始时间：{{item.createTime}}</div>
                    <div class="itemOpera">
                      <el-button type="primary" size="mini"  @click="_Clocked(item.id)">打卡</el-button>
                      <el-button type="danger" size="mini"  @click="_DeleteGoal(item.id)">删除目标</el-button>
                    </div>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
            <div class="Pagina_goal">
                <el-button size="mini" class="goal_item-pre" @click="GoalPreItem" :disabled="goal_pageable_pre">上一页</el-button>
                <el-button size="mini" class="goal_item-next" @click="GoalNextItem" :disabled="goal_pageable_next">下一页</el-button>
            </div>
          </div>
          <div class="RecordContent">
            <div class="RecordContentDetails">
              <el-collapse accordion v-model="activeNames_record">
                <el-collapse-item v-for="(item,index) in recordlist" :name="index" :key="index">
                  <template slot="title">
                    <img :src=item.icon class="ClockInAvatar">
                    <div class="ClockInName">{{item.goalName}}</div>
                    <div class="ClockTime">{{item.clockInTime}}</div>
                  </template>
                  <div class="">
                    <div class="GoalDescription">详情：{{item.description}}</div>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
            <div class="Pagina_record">
               <el-button size="mini" class="record_item-pre" @click="RecordPreItem" :disabled="record_pageable_pre">上一页</el-button>
               <el-button size="mini" class="record_item-next" @click="RecordNextItem" :disabled="record_pageable_next">下一页</el-button>
            </div>
          </div>
        </div>
        
      </el-main>
  </el-container>
  <div class="ClockIn_wrapper" v-show="DescriptionVisible">
    <div class="ClockContent">
      <div class="ClockTitle">
        输入您对当前目标完成的详细信息
      </div>
      <div class="ClockDescription">
        <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="recorddescriptopn" class="EditClockDes"></el-input>
      </div>
      <div class="btn-Clock">
        <el-button type="danger" size="mini"  @click="CancelClock" class="btn-itemcan">取消</el-button>
        <el-button type="primary" size="mini"  @click="ComfirmClocked" class="btn-itemcom">打卡</el-button>
      </div>

    </div>
    
  </div>
  </div>
</template>

<script>
import {GetGoalList,DeleteGoal} from '@/api/goal.js'
import {Clocked ,ClockedList} from '@/api/clock.js'
// import '@/assets/rem.js'
export default {
    name:'ActivityGoal',
    data(){
      return{
        usergoallist:[],
        usergoallistmiddle:[],
        activeNames_list:'0',
        activeNames_record:'0',
        recordlist:'',
        recorddescriptopn:'',
        DescriptionVisible:false,
        GoalId:'',

        pageNo:1,
        PageSize:10,

        goalcount:1,
        goal_pageable_pre:true,
        goal_pageable_next:false,


        recordcount : 1,
        record_pageable_pre:true,
        record_pageable_next:false
      }
    },
    created(){
      this._GetGoalList()
      this._GetClockedList()
    },
    methods:{
      _GetClockedList(){
        ClockedList(this.pageNo,this.PageSize).then(res=>{
          this.recordlist = res.data.list
          ClockedList(this.pageNo+1,this.PageSize).then(res=>{
          let nextmark = res.data.list
          if(nextmark.length == 0){
            this.record_pageable_next = true
          }else{
            this.record_pageable_next = false
          }
          
        })
        })
      },
      RecordPreItem(){
        this.recordcount -= 1
        if(this.recordcount == 1){
          this.record_pageable_pre = true
          this.record_pageable_next=false
          this.pageNo = this.recordcount
          this.PageSize = 10
          ClockedList(this.pageNo,this.PageSize).then(res=>{
            this.recordlist = res.data.list
          })
        }else{
          this.record_pageable_next=false
          this.pageNo = this.recordcount
          this.PageSize = 10
          ClockedList(this.pageNo,this.PageSize).then(res=>{
            this.recordlist = res.data.list
          })
        }
      },
      RecordNextItem(){
        this.recordcount += 1
        this.record_pageable_pre = false
        this.pageNo = this.recordcount
        this.PageSize = 10
        let indexmark = 0
        ClockedList(this.pageNo,this.PageSize).then(res=>{
          this.recordlist = res.data.list
          // ClockedList(this.pageNo+1,this.PageSize).then(res=>{
          //   indexmark = res.data.list.length
          //   if(indexmark == 0){
          //     this.record_pageable_next=true       
          //   }else{
          //     this.record_pageable_next=false 
          //   }
          // })
          if(this.recordlist.length == this.PageSize){
            this.record_pageable_next=false
          }else{
            this.record_pageable_next=true
          }
        })
      },
      _GetGoalList(){
          GetGoalList().then(res=>{
              this.usergoallist = res.data.list
              // this.usergoallist.reverse()
              this.usergoallistmiddle = this.usergoallist.slice(0,10)
              let nextmark = this.usergoallist.slice(10,20)
              if(nextmark.length == 0){
                this.goal_pageable_next = true
              }else{
                this.goal_pageable_next = false
              }
          })
      },
      GoalPreItem(){
        this.goalcount -= 1
        if(this.goalcount == 1){
          this.goal_pageable_pre = true
          this.goal_pageable_next = false
          this.usergoallistmiddle = this.usergoallist.slice((this.goalcount-1)*10,10*this.goalcount)
        }else{
          this.goal_pageable_next = false
          this.usergoallistmiddle = this.usergoallist.slice((this.goalcount-1)*10,10*this.goalcount)
        }
      },
      GoalNextItem(){
        this.goalcount += 1
        this.goal_pageable_pre = false
        this.usergoallistmiddle = this.usergoallist.slice((this.goalcount-1)*10,10*this.goalcount)
        let lastmark = this.usergoallist.slice((this.goalcount)*10,10*this.goalcount+1)
        if(lastmark.length == 0){
          this.goal_pageable_next = true
        }else{
          this.goal_pageable_next = false
        }
      },

      _DeleteGoal(id){
          if(window.confirm('是否删除该目标？')){
                DeleteGoal(id).then(res=>{
                  if(res.code == 0){
                      this.$message({
                          message: '删除成功！',
                          type: 'success'
                      });
                      this._GetGoalList()
                      this.GetGoalTotal()
                  }
              })
                return true;
            }else{
                return false;
            }
      },
      CancelClock(){
        this.DescriptionVisible = false
        this.recorddescriptopn = ''
      },
      _Clocked(id){
        this.DescriptionVisible = true
        this.GoalId = id
      },
      ComfirmClocked(){
        let clockData = {
            "goalId":this.GoalId,
            "description":this.recorddescriptopn
        }
        Clocked(clockData).then(res=>{
          if(res.code == 0){
            this.$message({
                message: '打卡成功！',
                type: 'success'
            });
            this._GetClockedList()
            this.recorddescriptopn = ''
            this.DescriptionVisible = false
            this.GetTotalRecord()
          }
        })
      }
      
    }
}
</script>

<style scoped>
.GoalAndRecord{
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.GoalAndRecord::-webkit-scrollbar{
  display: none;
}
.Title{
  font-size: 30px;
  border-bottom: 1px solid rgb(209, 209, 209);
  color: white;
}
.Header{
  height: 20%;
  width: 100%;
    display: flex;
}
.ListHeader{
  width: 50%;
  height: 20%;
  font-size: 20px;
  text-align: center;
  color: white;
}
.RecordHeader{
  width: 50%;
  height: 20%;
  font-size: 20px;
  text-align: center;
  color: white;
}
.Body{
  width: 100%;
  height: 60%;
  display: flex;
}
.GoalListContent{
  width: 50%;
  height: 450px;
  padding: 20px 10px;
  overflow: hidden;
}
.GoalListContent::-webkit-scrollbar{
  display: none;
}
.GoalListContentDetails{
  width: 100%;
  height: 90%;
  overflow: auto;
  border-radius: 4px 4px 0px 0px
}
.GoalListContentDetails::-webkit-scrollbar{
  display: none;
}
.Pagina_goal{
  position: relative;
  width: 100%;
  height: 40px;
  background-color: rgb(237 237 237);
  /* padding:6px 0px 0px 0px; */
  border-radius: 0px 0px 4px 4px;
  display: flex;
  align-items: center;
}
.goal_item-pre{
  position: absolute;
  left: 10px;
}
.goal_item-next{
  position: absolute;
  right: 10px;
}
.ItemInfo{
  height: 100px;
}
.itemTitle{
  text-align: left;
  height: 40px;
  padding: 0px 0px 0px 15px;
}
.itemDate{
  height: 20px;
  text-align: right;
  padding: 0px 5px 0px 0px;
}
.itemOpera{
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 5px 0px 0px;
}
.RecordContent{
  width: 50%;
  height: 450px;
  padding: 20px 10px 0px 10px;
  border-left: 1px solid #ebeef5;
  overflow: hidden;
}
.RecordContent::-webkit-scrollbar{
  display: none;
}
.RecordContentDetails{
  width: 100%;
  height: 90%;
  overflow: auto;
  border-radius: 4px 4px 0px 0px
}
.RecordContentDetails::-webkit-scrollbar{
  display: none;
}
.Pagina_record{
  position: relative;
    width: 100%;;
    height: 40px;
    background-color: rgb(237 237 237);
    /* padding:6px 0px 0px 0px; */
    border-radius: 0px 0px 4px 4px;
    display: flex;
    align-items: center;
}
.record_item-pre {
  position: absolute;
  left: 10px;
}
.record_item-next{
  position: absolute;
  right: 10px;
}


.ClockInAvatar{
  border-radius: 10px;
  width: 20px;
  height: 20px;
}
.ClockInName{
  padding-left: 20px;
  flex: 0 0 25%;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ClockTime{
  padding-left: 40px;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.GoalDescription{
  text-align: left;
  padding: 5px 5px 10px 15px;
}
.btn-Goal{
  padding: 5px;
  display: flex;
  justify-content: end;
}
.ClockIn_wrapper{
  position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0;
    overflow: hidden;
    background-color: rgba(202, 202, 202, 0.523);
    z-index: 500;
    /* display: none; */
}
.ClockContent{
  position: absolute;
  top: 30%;
  left: 50%;
  width: 400px;
  margin-left: -200px;
  height: 180px;
  background-color: #ffffff;
  border-radius: 4px;
}


.ClockTitle{
  height: 30px;
  line-height: 30px;
  font-size: 18px;
}
.ClockDescription{
  height: 100px;
  /* background-color: aqua; */
  width: 90%;
  margin: 0 auto;
  border-radius: 4px;
}
.EditClockDes{
  display: block;
}
.btn-Clock{
  width: 90%;
  height: 50px;
  margin: 0 auto;
  /* background-color: blueviolet; */
  display: flex;
  align-items: center;
  justify-content: end;
}
.btn-itemcom{
  position: absolute;
  right: 20px;
}
.btn-itemcan{
  position: absolute;
  left: 20px;
}


</style>